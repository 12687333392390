"use strict";

(function ($) {
  $(document).ready(function () {
    $(".preloader").fadeOut("fast");
    var isPreloaderShown = localStorage.setItem('preloaderShown', "true"); // HEADER-BURGER ========================================================================================================================================================

    $('.header__burger').on('click', function () {
      if ($(this).hasClass('active')) {
        $(this).toggleClass("active");
        $('body').css('overflow', 'auto');
        $('header').addClass('menu__hide');
        $('.container__header-menu').removeClass('open');
      } else {
        $('header').removeClass('menu__hide');
        $(this).toggleClass("active");
        $('body').css('overflow', 'hidden');
        $('.container__header-menu').addClass('open');
      }
    }); //SUB-MENU =======================================================================================================================

    $(".menu-item-has-children").click(function () {
      if (window.matchMedia('(max-width: 991px)').matches) {
        $(".sub-menu").not($(this).find(".sub-menu")).removeClass("open");
        $(this).find(".sub-menu").toggleClass("open");
        $(".menu-item-has-children").not($(this)).removeClass('open');
        $(this).toggleClass('open');
      }
    }); // HEADER-SCROLL ========================================================================================================================================================

    var scrollChange = 50;

    function headerScroll(params) {
      var scroll = $(window).scrollTop();

      if (scroll >= scrollChange) {
        $('.header').addClass('header_scroll');
      } else {
        $('.header').removeClass('header_scroll');
      }
    }

    $(document).on('scroll', function () {
      headerScroll();
    });
    headerScroll(); //APPEND BTN HEADER ==============================================================================================

    function appendBtn() {
      if (window.matchMedia("(min-width: 576px)").matches) {
        $('.header__btn').insertAfter('.header-menu__wrapper');
      } else {
        $('.header__btn').appendTo('.header-menu__wrapper');
      }
    }

    appendBtn();
    $(window).on('resize', function () {
      appendBtn();
    }); //VIDEO SECTION =============================================================================================================

    document.querySelectorAll('.video-section__wrapper').forEach(function (videoSection) {
      var video = videoSection.querySelector('.video-section__video');
      var observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            video.play();
          } else {
            video.pause();
          }
        });
      }, {
        threshold: 0.5
      });
      observer.observe(videoSection);
    }); // GET YEAR ========================================================================================================================================================

    if (document.querySelectorAll('.year').length > 0) {
      var yearSpan = document.querySelector('.year');
      var date = new Date();
      var yearFull = date.getFullYear();
      yearSpan.innerHTML = yearFull;
    } //SERVICES SLIDER==========================================================================================================================


    var slidersSmall = document.querySelectorAll('.sliderServicesSmall');
    slidersSmall.forEach(function (slider, index) {
      var sliderNumber = index + 1;
      slider.classList.add("sliderServicesSmall-".concat(sliderNumber));
    });
    var serviceSlider = document.querySelectorAll('.services-slider');
    serviceSlider.forEach(function (slider, index) {
      var sliderNumber = index + 1;
      slider.classList.add("services-slider-".concat(sliderNumber));
    });
    var swiperServicesSmall;
    var swiperServiceSlider;

    if ($(".sliderServicesSmall").length > 0) {
      swiperServicesSmall = new Swiper(".sliderServicesSmall-1", {
        initialSlide: 1,
        slidesOffsetAfter: 1,
        spaceBetween: 42,
        slidesPerView: 3,
        freeMode: false,
        watchSlidesProgress: true,
        centeredSlides: false,
        breakpoints: {
          220: {
            slidesPerView: 1.5,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20
          }
        },
        on: {
          slideChange: function slideChange() {
            if (swiperServicesSmall && swiperServicesSmall.activeIndex === 0) {
              this.params.centeredSlides = false;
            } else {
              if (window.innerWidth > 767) {
                this.params.centeredSlides = true;
              }
            }
          }
        }
      });
    }

    if ($(".services-slider").length > 0) {
      swiperServiceSlider = new Swiper(".services-slider-1", {
        spaceBetween: 30,
        parallax: true,
        speed: 1000,
        direction: 'horizontal',
        slidesPerView: 1,
        grabCursor: true,
        autoHeight: true,
        thumbs: {
          swiper: swiperServicesSmall
        },
        on: {
          slideChangeTransitionEnd: function slideChangeTransitionEnd() {
            var activeSlide = this.slides[this.activeIndex];
            var slideRect = activeSlide.getBoundingClientRect();
            var isOutOfView = slideRect.top < 0;
            var scrollOffset = 40;

            if (isOutOfView) {
              var scrollPosition = slideRect.top + window.pageYOffset - scrollOffset;
              window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth'
              });
            }
          },
          slideChange: function slideChange(swiperObj) {
            if (swiperServicesSmall) {
              var activeIndex = this.realIndex;
              swiperServicesSmall.slideTo(activeIndex, 1000);
              centerActiveSlide(swiperServicesSmall);
            }
          }
        }
      });
    }

    function centerActiveSlide(swiper) {
      var activeSlide = swiper.slides.filter(function (slide) {
        return slide.classList.contains('swiper-slide-thumb-active');
      })[0];

      if (activeSlide) {
        var activeSlideIndex = swiper.slides.indexOf(activeSlide);
        swiper.slideTo(activeSlideIndex, 0, false);
      }
    } // PAGE SERVICES SLIDER==========================================================================================================================


    var servicePageSlider = document.querySelectorAll('.services-page-slider');
    servicePageSlider.forEach(function (slider, index) {
      var sliderNumber = index + 1;
      slider.classList.add("services-page-slider-".concat(sliderNumber));
    });
    var swiperServicePageSlider;

    if ($(".services-page-slider").length > 0) {
      swiperServicePageSlider = new Swiper(".services-page-slider-1", {
        slidesPerView: 3.5,
        speed: 1000,
        spaceBetween: 30,
        loop: false,
        autoplay: false,
        scrollbar: {
          el: '.services-scrollbar .swiper-scrollbar',
          draggable: true,
          clickable: true
        },
        breakpoints: {
          220: {
            slidesPerView: 1.5,
            spaceBetween: 20
          },
          576: {
            slidesPerView: 2.5,
            spaceBetween: 20
          },
          1290: {
            slidesPerView: 3.5,
            spaceBetween: 30
          }
        }
      });
    } //SERVICE PAGE SLIDER 5 ELEMENTS =================================================================================================================


    var serviceElSliders = document.querySelectorAll('.service-el-slider');
    serviceElSliders.forEach(function (slider, index) {
      var sliderNumber = index + 1;
      slider.classList.add("service-el-slider-".concat(sliderNumber));
    });
    var serviceEls = [];

    function initserviceEl(sliderSelector) {
      return new Swiper(sliderSelector, {
        slidesPerView: 1,
        speed: 1000,
        spaceBetween: 30,
        autoplay: true,
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true
        },
        breakpoints: {
          220: {
            slidesPerView: 1,
            spaceBetween: 30
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 30
          }
        }
      });
    }

    function handleserviceEl() {
      if (window.innerWidth < 992) {
        serviceElSliders.forEach(function (slider, index) {
          if (!serviceEls[index]) {
            serviceEls[index] = initserviceEl(".service-el-slider-".concat(index + 1));
          }
        });
      } else {
        serviceEls.forEach(function (serviceEl) {
          if (serviceEl) {
            serviceEl.destroy();
          }
        });
        serviceEls = [];
      }
    }

    if (document.querySelectorAll(".service-el-slider__wrapper").length > 0) {
      window.addEventListener('load', handleserviceEl);
      window.addEventListener('resize', handleserviceEl);
    } //LOAD MORE BLOCK ==========================================================


    var initialVisibleServ = 6;
    var showMoreBtn = $('.btn-show-more');
    showMoreBtn.each(function () {
      var showMoreBtn = $(this);
      var loadMoreSection = showMoreBtn.closest('.load-more-section');
      var servItems = loadMoreSection.find('.load-more__item');
      servItems.slice(initialVisibleServ).hide();

      if (servItems.length <= initialVisibleServ) {
        showMoreBtn.hide();
      }

      showMoreBtn.on('click', function (event) {
        var buttonAttr = $(this).attr('data-state');

        if (buttonAttr === 'hideServ') {
          $(this).attr('data-state', 'showServ');
          $(this).text('Hide Services');
          servItems.slice(initialVisibleServ).slideDown();
        } else {
          $(this).attr('data-state', 'hideServ');
          $(this).text('Mehr lesen');
          servItems.slice(initialVisibleServ).slideUp();
        }
      });
    }); //ANIMATION BANNER BLOCK ===================================================

    var bannerImages = document.querySelectorAll(".section-banner__img");

    if (bannerImages.length > 0) {
      var controller = new ScrollMagic.Controller();
      bannerImages.forEach(function (image) {
        var isScrolled = false;
        var scrollPercentage = 0;
        gsap.set(image, {
          scaleX: 0.50,
          scaleY: 0.50,
          transformOrigin: 'center center'
        });
        var scene = new ScrollMagic.Scene({
          triggerElement: image,
          triggerHook: 'onEnter',
          duration: '30%'
        }).addTo(controller).on('progress', function (event) {
          var progress = event.progress;
          var direction = progress > scrollPercentage ? 'FORWARD' : 'REVERSE';
          isScrolled = true;
          scrollPercentage = progress;

          if (direction === 'FORWARD' && isScrolled) {
            var scale = 0.80 + 0.2 * progress;
            gsap.to(image, {
              scaleX: scale,
              scaleY: scale,
              duration: 0.3
            });
          } else {
            if (isScrolled && progress > 0.8) {
              gsap.to(image, {
                scaleX: 0.80,
                scaleY: 0.80,
                duration: 0.3
              });
            }
          }
        });
      });
    } // PAGES CONTENT SECTION==================================================================================


    var observerContentSection = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry, index) {
        if (entry.isIntersecting) {
          var tl = new TimelineMax();
          tl.fromTo(entry.target, {
            autoAlpha: 0,
            y: 50
          }, {
            autoAlpha: 1,
            y: 0,
            duration: 1,
            ease: Power2.easeInOut,
            delay: index * 0.2
          });
          observer.unobserve(entry.target);
        }
      });
    }, {
      threshold: 0.5
    });
    var items = document.querySelectorAll('.content-section-el');
    items.forEach(function (item) {
      observerContentSection.observe(item);
    }); //ANIMATION BLOCKS ================================================    

    gsap.registerPlugin(ScrollTrigger);

    if ($(".animate-wrap").length > 0) {
      $(".animate-wrap").each(function () {
        var animateElements = $(this).find(".animate");

        if (animateElements.length > 0) {
          animateElements.each(function (index, element) {
            gsap.fromTo(element, {
              y: 100,
              opacity: 0
            }, {
              y: 0,
              opacity: 1,
              duration: 1,
              delay: index * 0.2,
              scrollTrigger: {
                trigger: element,
                start: "top 80%",
                end: "bottom 20%",
                toggleActions: "play none none none"
              }
            });
          });
        }
      });
    } //ANIMATION ALL PAGES MAIN IMAGE ==================================================================================


    var pagesMainSectionIMG = document.querySelectorAll(".pages-main-section__img");
    var contactsSectionWrapper = document.querySelectorAll(".contacts-section__wrapper");

    var intersectionCallbackMainIMG = function intersectionCallbackMainIMG(entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          gsap.fromTo(entry.target, {
            scale: 0.5
          }, {
            scale: 1,
            duration: 1.5,
            ease: "power2.out"
          });
          observer.unobserve(entry.target);
        }
      });
    };

    var observerMainIMG = new IntersectionObserver(intersectionCallbackMainIMG);
    pagesMainSectionIMG.forEach(function (sectionImage) {
      observerMainIMG.observe(sectionImage);
    });
    contactsSectionWrapper.forEach(function (sectionImage) {
      observerMainIMG.observe(sectionImage);
    }); //TITLE LETTER ANIMATION ==========================================================================================================

    if ($(".title").length > 0) {
      var title = document.querySelectorAll('.title');
      title.forEach(function (elem) {
        var splitText = function splitText(el) {
          el.innerHTML = el.textContent.replace(/(\S*)/g, function (m) {
            return "<span class=\"word\">" + m.replace(/(-|#|@)?\S(-|#|@)?/g, "<span class='letter'>$&</span>") + "</span>";
          });
          return el;
        };

        var split = splitText(elem);
      });
      gsap.from('.title span.letter', {
        opacity: 0,
        y: 30,
        duration: 1.5,
        stagger: 0.05
      });
    } //ANIMATION SERVICE ELEMENT ===================================================================


    if (window.innerWidth > 991) {
      $(".services-el-section").each(function () {
        var serviceElements = $(this).find(".service-el");

        if (serviceElements.length > 0) {
          serviceElements.each(function (index, element) {
            gsap.fromTo(element, {
              y: 100,
              opacity: 0
            }, {
              y: 0,
              opacity: 1,
              duration: 1,
              delay: index * 0.2,
              scrollTrigger: {
                trigger: element,
                start: "top 80%",
                end: "bottom 20%",
                toggleActions: "play none none none"
              }
            });
          });
        }
      });
    } //ONE SERVICE PAGE ANIMATION IMAGE ================================================================================


    if ($(".service-main-section").length > 0) {
      var serviceMainSections = document.querySelectorAll('.service-main-section');
      serviceMainSections.forEach(function (section) {
        document.querySelectorAll('.service-main-section-image').forEach(function (icon, index) {
          gsap.fromTo(icon, {
            y: 100,
            opacity: 0
          }, {
            y: 0,
            opacity: 1,
            duration: 1.5,
            ease: "back.out(1.2)"
          });
        });
      });
    } //ANIMATION HERO SECTION ============================================================================================


    gsap.registerPlugin(ScrollToPlugin);
    var panelWrappers = document.querySelectorAll(".panel-wrapper");

    if ($(".panel-wrapper").length > 0) {
      panelWrappers.forEach(function (panelWrapper) {
        if (window.innerWidth > 991) {
          var goToSection = function goToSection(i) {
            scrollTween = gsap.to(window, {
              scrollTo: {
                y: i * innerHeight,
                autoKill: false
              },
              onStart: function onStart() {
                observer.disable(); // for touch devices, as soon as we start forcing scroll it should stop any current touch-scrolling, so we just disable() and enable() the normalizeScroll observer

                observer.enable();
              },
              duration: 1,
              onComplete: function onComplete() {
                return scrollTween = null;
              },
              overwrite: true
            });
          };

          var panels = gsap.utils.toArray(panelWrapper.querySelectorAll(".wrap .panel")),
              observer = ScrollTrigger.normalizeScroll(true),
              scrollTween; // on touch devices, ignore touchstart events if there's an in-progress tween so that touch-scrolling doesn't interrupt and make it wonky

          document.addEventListener("touchstart", function (e) {
            if (scrollTween) {
              e.preventDefault();
              e.stopImmediatePropagation();
            }
          }, {
            capture: true,
            passive: false
          });
          panels.forEach(function (panel, i) {
            ScrollTrigger.create({
              trigger: panel,
              start: "top bottom",
              end: "+=199%",
              onToggle: function onToggle(self) {
                return self.isActive && !scrollTween && goToSection(i);
              }
            });
          });
        } //OBSERVER


        var targetElement = panelWrapper.querySelector(".p-second__content");
        var mainImage = panelWrapper.querySelector(".p-second__main-image");

        if (window.innerWidth > 991) {
          var observerMainImage = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
              if (entry.isIntersecting) {
                gsap.to(mainImage, {
                  x: "60%",
                  top: "60%",
                  translateY: "-60%",
                  scale: 1,
                  duration: 1,
                  ease: "power1.out" // marginBlock: "auto"

                });
              } else if (window.scrollY <= mainImage.offsetTop + mainImage.offsetHeight) {
                gsap.to(mainImage, {
                  x: 0,
                  top: "-10%",
                  translateY: "-50%",
                  scale: 1.5,
                  ease: "power1.out"
                });
              }
            });
          }, {
            threshold: 0.5
          });
          observerMainImage.observe(targetElement);
        }

        var observerScreen2Content = new IntersectionObserver(function (entries) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              gsap.fromTo(panelWrapper.querySelector(".p-second__content"), {
                y: 300
              }, {
                y: 0,
                duration: 1.5,
                ease: "power1.out",
                opacity: 1
              });
              observerScreen2Content.unobserve(entry.target);
            }
          });
        }, {
          threshold: 0.5
        });
        observerScreen2Content.observe(targetElement); // FIRST HERO ANIMATION

        gsap.fromTo(panelWrapper.querySelector(".first h1"), {
          y: 100,
          opacity: 0
        }, {
          y: 0,
          opacity: 1,
          duration: 1,
          ease: "back.out(1.2)",
          onComplete: function onComplete() {
            panelWrapper.querySelector(".first").classList.add("active");
          }
        });
        gsap.fromTo(panelWrapper.querySelectorAll(".hero-panel__abs-image:nth-child(even)"), {
          y: 100,
          opacity: 0
        }, {
          y: 0,
          opacity: 1,
          duration: 1,
          ease: "back.out(1.2)"
        });

        if (window.innerWidth > 991) {
          gsap.fromTo(panelWrapper.querySelector(".p-second__main-image"), {
            y: 100,
            opacity: 0,
            scale: 0
          }, {
            y: "-50%",
            top: "-10%",
            opacity: 1,
            scale: 1.5,
            duration: 1,
            ease: "back.out(1.2)"
          });
        }

        if (window.innerWidth < 991) {
          gsap.fromTo(panelWrapper.querySelector(".p-second__main-image"), {
            y: 100,
            opacity: 0,
            scale: 0
          }, {
            y: 0,
            top: "0",
            opacity: 1,
            scale: 1,
            duration: 1,
            ease: "back.out(1.2)"
          });
        }

        gsap.fromTo(panelWrapper.querySelector(".hero-panel__abs-image:nth-child(1)"), {
          y: "50vh",
          opacity: 0
        }, {
          y: 0,
          opacity: 1,
          duration: 1,
          ease: "back.out(1.2)",
          onComplete: function onComplete() {
            panelWrapper.querySelectorAll(".hero-panel__abs-image").forEach(function (element) {
              element.classList.add("active");
            });
          }
        });
        gsap.fromTo(panelWrapper.querySelector(".hero-panel__abs-image:nth-child(3)"), {
          y: "50vh",
          opacity: 0
        }, {
          y: 0,
          opacity: 1,
          duration: 1.2,
          ease: "back.out(1.2)"
        }); //MOVE ELEMENTHS ON SCROLL

        var timeline = gsap.timeline({
          scrollTrigger: {
            trigger: panelWrapper.querySelector(".wrap"),
            start: "top top",
            end: "100%",
            scrub: true
          }
        });
        timeline.to(panelWrapper.querySelector(".hero-panel__abs-image:nth-of-type(1)"), {
          rotation: "70deg",
          x: -100,
          duration: 1
        });
        timeline.to(panelWrapper.querySelector(".hero-panel__abs-image:nth-of-type(2)"), {
          rotation: "70deg",
          x: -150,
          duration: 1
        }, "-=1");
        timeline.to(panelWrapper.querySelector(".hero-panel__abs-image:nth-of-type(3)"), {
          rotation: "70deg",
          x: 100,
          duration: 1
        }, "-=1");
        timeline.to(panelWrapper.querySelector(".hero-panel__abs-image:nth-of-type(4)"), {
          rotation: "70deg",
          x: 150,
          duration: 1
        }, "-=1");
      });
    } //STICKY SERVICES SLIDER ========================================================


    var servNav = document.querySelector('.slider-container');
    var servicesSectionBanner = document.querySelector('.services-section__banner');
    var servSection = document.querySelector('.services-section');
    var bannerBottom;
    var sectionTop;
    var sectionBottom;

    if (servicesSectionBanner && servSection) {
      bannerBottom = servicesSectionBanner.getBoundingClientRect().bottom + window.scrollY;
      sectionTop = servSection.getBoundingClientRect().bottom + window.scrollY - 300;
      sectionBottom = sectionTop + servSection.offsetHeight;
    }

    function toggleFixedNav() {
      var scrollY = window.scrollY;

      if (scrollY >= bannerBottom && scrollY <= sectionTop) {
        servNav.classList.add('fixed');
        $('.header.header_scroll').addClass('hidden');
      } else {
        servNav.classList.remove('fixed');
        $('.header.header_scroll').removeClass('hidden');
      }
    }

    if (servicesSectionBanner && servSection) {
      window.addEventListener('scroll', toggleFixedNav);
    } //FILTER BTN ============================================================================================


    var filterBtn = $('.filter-btn');
    var filterWrap = $('.filter-list__wrap');
    filterBtn.click(function (e) {
      if ($(window).width() < 575) {
        filterWrap.slideToggle();
      }

      e.stopPropagation();
    });
    $(document).on("click", function (e) {
      if (!filterBtn.is(e.target) && filterBtn.has(e.target).length === 0 && !filterWrap.is(e.target) && filterWrap.has(e.target).length === 0) {
        $(".filter-list__wrap").slideUp();
      }
    });
  });
})(jQuery);